import type { FlagMap } from './FlagsTypes'

const defaultFlags: FlagMap = {
  salesforce: {
    enabled: false,
    messageLogging: false,
  },
  contactNoteReactions: false,
  onboardingSubscriptionFraudTexts: false,
  sentry: false,
  sentryTracesSampleRate: 0,
  webSentryBrowserProfilingSampleRate: 0,
  webAdaChatBot: false,
  heap: false,
  messagingPolicyWarning: false,
  panelResizing: 'disabled',
  groupCallingListener: false,
  groupCallingWhispering: false,
  groupCallingAddMultipleParticipants: false,
  profilePageReferralCallout: false,
  minAutoChargeAmountCents: 0,
  callTranscripts: false,
  maxPinnableConversations: 0,
  downloadImageButton: false,
  phoneNumberPurchasing: { blocked: false },
  fetchContactsBatchLimit: 200,
  outageBanner: false,
  a2pStirShaken: false,
  disableCnamSubmissions: false,
  userIdleTimeout: 60_000,
  appAutoUpdateDelayMs: 600_000,
  webUserAudioSettings: false,
  contactTagFiltering: false,
  phoneNumberGroupMember: false,
  billingRevamp: false,
  a2pBlockMessaging: false,
  tollFreeMessageBlocking: false,
  tollFreeFailedBackfill: false,
  webCallActivityClarity: false,
  pricingV6: false,
  viewers: false,
  webConversationLoadNewIndexedDBQuerying: false,
  webActivityStoreQueryIndexedDBQueryDirectly: false,
  trustCenterTollFree: false,
  webBusinessPlanPreview: false,
  webNoiseSuppression: false,
  webCallDetailsPanel: false,
  webSelfServeDataExport: false,
  callViews: false,
  webRecentHistory: false,
  webReEnableCnam: false,
  webCallQualityFeedbackSampleRate: 0,
  webCallQualityFeedback: false,
  webSurvicate: '',
  webPrivacyConsentImprovements: false,
  webNewDataLayerInboxCompanyFilterCombobox: false,
  webApiKeyGeneration: false,
  webLiveChatBadge: false,
  webWorkflowEngine: false,
  webUniversalLogin: false,
}

export default defaultFlags
